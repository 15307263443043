import "./App.css";
import Row from "react-bootstrap/Row";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Item from "./Components/Item";
import { Accordion } from "react-bootstrap";
import data from "./data/data.json";
import Header from "./Components/Header";
import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";


function App() {
  ReactGA.initialize("UA-64709919-8");
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="App">
      <Container>
        <Col>
          <div className="main-app">
            <Header />
            <Row>
              <Col>
                <Accordion defaultActiveKey={data.map((v) => v.id)} alwaysOpen>
                  {data.map((item, index) => (
                    <Item key={item.id} index={++index} item={item}></Item>
                  ))}
                </Accordion>
              </Col>
            </Row>
            {showTopBtn && (
              <svg
                onClick={goToTop}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-arrow-up-circle-fill go-top-btn"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
              </svg>
            )}
          </div>
        </Col>
      </Container>
    </div>
  );
}

export default App;
