import "./Item.css";
import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import AnalyticsEventTracker from "./AnalyticsEventTracker";

function Item(props) {
  const { item, index } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const audio = new Audio(item.sound);

  const startAudio = () => {
    setIsPlaying(true);
    audio.play();
    gaEventTracker(`clicked on: ${item.title.ar}`, 'anyTest' + item.id);
  };

  useEffect(() => {
    audio.addEventListener("ended", () => setIsPlaying(false));
  });

  const gaEventTracker = AnalyticsEventTracker('ListOfCarParts');


  return (
    <div className="card-main">
      <Accordion.Item eventKey={item.id} key={item.id}>
        <Accordion.Header as="h6">
          {index}. {item.title.tr} - {item.title.ar}
        </Accordion.Header>
        <Accordion.Body>
          <Card key={item.id}>
            <div className="card-img-main" onClick={startAudio}>
              <Card.Img variant="top" src={item.image} />
              <span className="card-img-span">
                {isPlaying && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-volume-up"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z" />
                    <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z" />
                    <path d="M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z" />
                  </svg>
                )}
                {!isPlaying && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-volume-down"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9 4a.5.5 0 0 0-.812-.39L5.825 5.5H3.5A.5.5 0 0 0 3 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 9 12V4zM6.312 6.39 8 5.04v5.92L6.312 9.61A.5.5 0 0 0 6 9.5H4v-3h2a.5.5 0 0 0 .312-.11zM12.025 8a4.486 4.486 0 0 1-1.318 3.182L10 10.475A3.489 3.489 0 0 0 11.025 8 3.49 3.49 0 0 0 10 5.525l.707-.707A4.486 4.486 0 0 1 12.025 8z" />
                  </svg>
                )}
              </span>
            </div>
            <Card.Body>
              <Card.Text>{item.desc}</Card.Text>
            </Card.Body>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </div>
  );
}

export default Item;
