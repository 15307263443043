import React from "react";
import "./Header.css";
import Card from "react-bootstrap/Card";

const Header = () => {
  return (
    <div className="header-text">
      <Card>
        <Card.Body>
          <Card.Title as="h5">أسماء قطع السيارة المطروحة في الإمتحان العملي</Card.Title>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Header;
